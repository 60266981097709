import get from 'lodash.get';
import { t1 } from 'translate';
import { courseLayout } from 'configs/constants';
import lodashGet from 'lodash.get';
import { courseShouldDisplayOverview } from 'components/front-end/course/utils';
import { isGuide } from 'utils/Util';
import { isEtepDaitraTpCategory } from 'components/admin/training-plan/common';
import { isSurvey } from 'components/admin/node/utils';
import { isNotOEExercise } from 'common/learn/Question';

export const getNodeIcon = (node) => {
  let nodeType;
  if (node && get(node, 'ntype')) {
    if (get(node, 'ntype') === 'video') {
      nodeType = `video-${get(node, 'type') ? get(node, 'type') : 'video'}`;
    } else if (get(node, 'type') === 'exam') {
      nodeType = get(node, 'type');
    } else {
      nodeType = get(node, 'ntype');
    }
  }

  return nodeType;
};

export const formatThousandNumber = (data) => {
  return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const displayDuration = (duration) => {
  const formattedDuration = duration.split(':');
  const length = formattedDuration.length;
  const hour =
    formattedDuration[length - 3] && formattedDuration[length - 3] >= 10
      ? formattedDuration[length - 3]
      : formattedDuration[length - 3] % 10;
  const minute =
    formattedDuration[length - 2] && formattedDuration[length - 2] >= 10
      ? formattedDuration[length - 2]
      : formattedDuration[length - 2] % 10;
  const second =
    formattedDuration[length - 1] && formattedDuration[length - 1] >= 10
      ? formattedDuration[length - 1]
      : formattedDuration[length - 1] % 10;
  return hour
    ? `${hour}h ${minute}m ${second}s`
    : minute
    ? `${minute}m ${second}s`
    : `${second}s`;
};

const courseBackLinkKey = 'çourse-back-link';
export const setBackLinkWhenCloseCourse = (backLink) => {
  localStorage.setItem(courseBackLinkKey, backLink);
};

export const getBackLinkWhenCloseCourse = () => {
  return localStorage.getItem(courseBackLinkKey);
};

const scoBackLinkKey = 'sco-back-link';
export const setBackLinkWhenCloseSCO = (backLink) => {
  localStorage.setItem(scoBackLinkKey, backLink);
};

export const getBackLinkWhenCloseSCO = () => {
  return localStorage.getItem(scoBackLinkKey);
};

export const getLearningProgressBasedOnSpentTime = ({
  startTime,
  endTime,
  spentTime,
  spentTimeCalculatePoint,
}) => {
  const localSpentTime = (new Date() - spentTimeCalculatePoint) / 1000;
  const totalSpentTime = spentTime + localSpentTime;
  return {
    learningProgress: Math.ceil((totalSpentTime / (endTime - startTime)) * 100),
    spentTime: localSpentTime,
    totalSpentTime,
  };
};

export const learnItemTypes = {
  video: 'video',
  pdf: 'pdf',
};

export const learnItemTypeExtra = {
  [learnItemTypes.video]: {
    prefix: t1('note_at'),
    key: 'currentDuration',
  },
  [learnItemTypes.pdf]: {
    prefix: t1('note_at_page'),
    key: 'currentPage',
  },
};

export const isCourseInteractiveLayout = (learnLayout) =>
  learnLayout === courseLayout.INTERACTIVE;

export const isCourseDefaultLayout = (learnLayout) =>
  learnLayout === courseLayout.DEFAULT;

export const calculateContentSectionHeight = (
  sectionElement,
  excludePX = 0,
) => {
  const offsetTopOfNav = sectionElement
    ? sectionElement.getBoundingClientRect().top
    : 0;
  return window.innerHeight - offsetTopOfNav - excludePX;
};

export const shouldShowSessionTab = (course) => {
  return (
    lodashGet(course, 'learning_type') === 'ilt' &&
    !courseShouldDisplayOverview(course)
  );
};

export const shouldShowCommentTab = (course, learnItem) => {
  const isGuideCourse = isGuide(course);
  const isSurveyItem = isSurvey(learnItem);
  const isNTypeSyllabus = course.ntype === 'syllabus';
  const allowComment =
    (course.allow_comment || isNTypeSyllabus) && !isSurveyItem;

  return allowComment && !isGuideCourse;
};

export const shouldShowAskTeacherTab = (course) => {
  const isGuideCourse = isGuide(course);
  const categoryId = get(course, 'training_plan_category');
  const isThamKhao = get(course, 'is_thamkhao');

  const allowAskTeacher =
    (course.allow_ask_teacher || isEtepDaitraTpCategory(categoryId)) &&
    !isThamKhao;

  return allowAskTeacher && !isGuideCourse;
};

export const TAB_KEY = {
  NAV: 'nav',
  OVERVIEW: 'overview',
  SESSION: 'course-sessions',
  QA: 'qa',
  ASK_TEACHER_TO_ANSWER: 'ask-teacher-to-answer',
  NOTE: 'note',
};

export const isIidEqual = (iid1, iid2) => String(iid1) === String(iid2);

export const getAllChildrenOfNav = (navTree) => {
  if (!navTree) {
    return [];
  }

  const { children = [] } = navTree;

  if (children.length) {
    let result = [];
    children.forEach((c) => {
      getAllChildrenOfNav(c).map((i) => result.push(i));
    });

    return [...result, navTree];
  }

  return [navTree];
};

export const isQuestionMissing = (exercise) => {
  const isOEExercise = !isNotOEExercise(exercise);
  if (isOEExercise) {
    return false;
  }

  return (exercise || {}).isQuestionMissing;
};

export const isQuestionEmpty = (exercise) => {
  const children = get(exercise, 'children', []);
  return !children.length;
};

export const showMessageForQuestionInVideo = (item) => {
  const { questions = [], type = '' } = item;
  return Array.isArray(questions) && !!questions.length && type === 'video';
};

export const isAnswerSaveSuccess = (saveTakeSuccess, name) => {
  if (Array.isArray(saveTakeSuccess)) {
    return saveTakeSuccess.includes(name);
  }
  return false;
};

export const shouldShowCourseWarning = (courseIid) => {
  if (!courseIid) {
    return false;
  }

  const courseWarningSetting = get(window, 'courseWarning') || {};
  if (!courseWarningSetting[courseIid]) {
    return false;
  }

  const storageKey = `course-warning-${courseIid}`;
  const hasShownCourseWarning = localStorage.getItem(storageKey);
  if (hasShownCourseWarning) {
    return false;
  }

  return true;
};

export const getCourseWarning = (courseIid) => {
  const courseWarningSetting = get(window, 'courseWarning') || {};

  return courseWarningSetting[courseIid];
};

export const setCourseWarningHasShown = (courseIid) => {
  const storageKey = `course-warning-${courseIid}`;

  localStorage.setItem(storageKey, '1');
};
