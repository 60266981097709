import React, { Component } from 'react';
import { connect } from 'react-redux';
import lodashGet from 'lodash.get';
import { submit, change, getFormValues, isSubmitting, reset } from 'redux-form';
import { t1 } from 'translate';
import { createSelector } from 'reselect';

import Pagination from 'components/common/pagination';
import sagaActions from 'actions/node/saga-creators';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import { diffObjects } from 'common/utils/object';
import Results from 'components/common/search-wrap/Results';
import Form from 'schema-form/Form';
import Loading from 'components/common/loading';
import withFeatureFlags from 'feature-flag/withFeatureFlags';
import actions from 'actions/creators';
import nodeActions from 'actions/node/creators';
import debounce from 'lodash.debounce';

import {
  hasAnySearchResultSelector,
  resultFromStoreSelector,
  searchResultIdSelector,
  searchResultObjectsSelector,
  searchResultsSelector,
  searchResultTotalSelector,
  searchResultShowTotalItemSelector,
  searchValuesSelector,
} from 'components/common/search-wrap/common';

const DEFAULT_PAGE_NUMBER = 1;
let timeout = {};

class SearchWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _sand_get_total: 1,
      pageNumber: DEFAULT_PAGE_NUMBER,
      itemsPerPage:
        props.itemsPerPage ||
        lodashGet(props, 'paginationProps.itemPerPage.0') ||
        10,
    };
  }

  componentDidMount() {
    // const { hiddenFields, dispatch, formid } = this.props;
    // Object.keys(hiddenFields || {}).forEach((key) => {
    //   dispatch(change(formid, key, hiddenFields[key]));
    // });

    // setTimeout(() => {
    this.initSearch();
    // }, 0);
  }

  componentWillReceiveProps(nextProps) {
    const { hiddenFields, formid, dispatch, alternativeApi } = this.props;
    const nextHiddenFields = nextProps.hiddenFields;

    if (
      (nextProps && nextProps.formid !== formid) ||
      nextProps.alternativeApi !== alternativeApi
    ) {
      this.initSearch(true);
      return;
    }

    if (hiddenFields && nextHiddenFields && nextHiddenFields !== hiddenFields) {
      Object.keys(nextHiddenFields).forEach((key) => {
        if (!isEqual(nextHiddenFields[key], hiddenFields[key])) {
          dispatch(change(formid, key, nextHiddenFields[key]));
        }
      });
      Object.keys(hiddenFields).forEach((key) => {
        if (hiddenFields[key] && !nextHiddenFields[key]) {
          dispatch(change(formid, key, null));
          nextHiddenFields[key] = null;
        }
      });
    }

    // if (nextProps && nextProps.formid !== formid) {
    //   this.initSearch();
    // }
  }

  initSearch = (autoSearch = false) => {
    const { formid, dispatch, hiddenFields, schema } = this.props;

    Object.keys(hiddenFields || {}).forEach((key) => {
      dispatch(change(formid, key, hiddenFields[key]));
    });

    if (timeout[formid]) clearTimeout(timeout[formid]);

    const searchFormHasFields = schema && Object.keys(schema).length;

    timeout[formid] = setTimeout(() => {
      if (
        autoSearch ||
        this.props.autoSearchWhenStart ||
        (typeof this.props.autoSearchWhenStart === 'undefined' &&
          this.props.autoSearchWhenStartConfig)
      ) {
        if (searchFormHasFields) {
          dispatch(submit(formid));
        } else {
          this.search();
        }
      }
    }, 10);
  };

  componentDidUpdate(prevProps) {
    const {
      hiddenFields,
      autoSearchWhenValuesChange,
      autoSearchWhenHiddenFieldsChange,
    } = this.props;
    if (!isEqual(prevProps.formValues, this.props.formValues)) {
      const hiddenFieldsChanged =
        hiddenFields &&
        Object.keys(hiddenFields).reduce((result, key) => {
          if (
            prevProps.formValues &&
            this.props.formValues &&
            !isEqual(this.props.formValues[key], prevProps.formValues[key])
          ) {
            return true;
          }
          return result;
        }, false);
      if (
        (hiddenFieldsChanged && autoSearchWhenHiddenFieldsChange) ||
        autoSearchWhenValuesChange
      ) {
        this.search();
      }
    }
  }

  componentWillUnmount() {
    const { dispatch, localSearchResultKey } = this.props;
    dispatch(nodeActions.cleanSearchResults(localSearchResultKey));
  }

  clearPaginationToPageDefault = () => {
    this.setState({
      pageNumber: DEFAULT_PAGE_NUMBER,
    });
  };

  setOptionGetTotal = (value) => {
    this.setState({
      _sand_get_total: value,
    });
  };

  handleSubmit = () => {
    const {
      valuesToSubmit,
      fieldsToPersistValueLocalstorage,
      formid,
      dispatch,
    } = this.props;
    if (
      Array.isArray(fieldsToPersistValueLocalstorage) &&
      fieldsToPersistValueLocalstorage.length
    ) {
      const values = {};
      fieldsToPersistValueLocalstorage.forEach((field) => {
        if (typeof valuesToSubmit[field] !== 'undefined') {
          values[field] = valuesToSubmit[field];
        }
      });

      if (Object.keys(values).length) {
        dispatch(actions.changeValueOfFieldsHaveToPersist({ formid, values }));
      }
    }

    this.search();
    if (typeof this.props.onSubmit === 'function') {
      this.props.onSubmit();
    }
  };

  handlePageChange = (pageNumber, itemsPerPage) => {
    const {
      pageNumber: pageNumberState,
      itemsPerPage: itemsPerPageState,
    } = this.state;
    if (pageNumber === pageNumberState && itemsPerPage === itemsPerPageState) {
      return;
    }

    this.search({
      pageNumber,
      itemsPerPage,
    });
    this.setState({ pageNumber, itemsPerPage });
  };

  search = (data = {}) => {
    const enableDebounceTime = this.props.enableDebounceTime;

    if (enableDebounceTime) {
      return this.debounceHandleSearch(data);
    }

    return this.handleSearch(data);
  };

  debounceHandleSearch = debounce((data) => {
    this.handleSearch(data);
  }, 300);

  handleSearch = (data = {}) => {
    const props = data.props || this.props;
    const extraParams = data.extraParams || {};
    const pageNumber = data.pageNumber || this.state.pageNumber;
    const itemsPerPage = data.itemsPerPage || this.state.itemsPerPage;
    const { method } = this.props;

    const {
      valuesToSubmit,
      formid,
      dispatch,
      hiddenFields,
      formValues,
      localSearchResultKey,
      prepareDataBeforeSearch,
      alternativeApi,
      onFail,
      onSuccess,
      autoSearchWhenValuesChange,
      showMessageFromServer,
      callApiMultipleTimesSettings,
      reduxFormDispatchSubmit,
    } = props;

    const localValues = {
      _sand_get_total: reduxFormDispatchSubmit ? 1 : this.state._sand_get_total,
      ...(valuesToSubmit || {}),
      ...(extraParams || {}),
      submit: 1,
    };

    if (pageNumber) {
      localValues.page = pageNumber;
    }

    if (lodashGet(hiddenFields, 'items_per_page') || itemsPerPage) {
      localValues.items_per_page =
        lodashGet(hiddenFields, 'items_per_page') || itemsPerPage;
    }

    let localValuesSendToServer = localValues;
    if (prepareDataBeforeSearch) {
      localValuesSendToServer = prepareDataBeforeSearch(
        localValuesSendToServer,
      );
    }

    if (
      typeof autoSearchWhenValuesChange === 'function' &&
      !autoSearchWhenValuesChange(localValuesSendToServer)
    ) {
      return;
    }

    this.setOptionGetTotal(0);
    dispatch(
      sagaActions.fetchNodesRequest(
        localValuesSendToServer,
        formid,
        localSearchResultKey,
        alternativeApi,
        {
          onSuccess,
          onFail,
          method,
          showMessageFromServer,
          callApiMultipleTimesSettings,
        },
      ),
    );

    // sync localValues to redux form (hiddenFields, sort data, ...)
    const { deleted, added, updated } = diffObjects(formValues, {
      ...(formValues || {}),
      ...(hiddenFields || {}),
      ...(extraParams || {}),
    });
    Object.keys(added).forEach((key) => {
      dispatch(change(formid, key, added[key]));
    });
    Object.keys(deleted).forEach((key) => {
      dispatch(change(formid, key, null));
    });
    Object.keys(updated).forEach((key) => {
      dispatch(change(formid, key, updated[key]));
    });
  };

  handleChange = (values) => {
    const { autoSearchWhenValuesChange, onChange } = this.props;
    this.clearPaginationToPageDefault();
    this.setOptionGetTotal(1);

    if (autoSearchWhenValuesChange) {
      this.search({ pageNumber: DEFAULT_PAGE_NUMBER });
    }
    if (typeof onChange === 'function') {
      onChange(values);
    }
  };

  handleSortDataChange = (sortData) => {
    const { fromSortDataToParams } = this.props;
    let extraParams = sortData;
    if (typeof fromSortDataToParams === 'function') {
      extraParams = fromSortDataToParams(sortData);
    }
    this.search({
      extraParams,
    });
  };

  isGettingTotal = (total, items) => {
    if (total || total === 0) {
      return false;
    }

    return Array.isArray(items) && items.length;
  };

  render() {
    const {
      formid,
      items,
      total,
      hiddenFields,
      showSearchButton,
      searchButtonText,
      hidePagination,
      paginationProps,
      validate,
      destroyOnUnmount,
      initialValues,
      schema,
      params,
      classWrapper,
      classFormFilter,
      classResultWrapper,
      classPaginationWrapper,
      renderLoadingComponent,
      isFormSubmitting,
      hasAnySearchResult,
      paginationPosition,
      alwaysShowResult,
      noNeedBackground,
      renderPagination,
      showTotalItem,
      showResultsWhenSubmitSucceeded,
    } = this.props;

    let { ntype } = this.props;
    ntype = ntype || (hiddenFields && hiddenFields.ntype);

    let style = {
      borderRadius: 5,
    };

    if (!noNeedBackground) {
      style = {
        ...style,
        background: 'white',
      };
    }

    const submitLabels = this.props.submitLabels || {
      submitting: '.....',
      default: t1('search'),
    };

    const searchFormHasFields = schema && Object.keys(schema).length;

    const searchForm = (
      <Form
        formid={formid}
        form={formid}
        hiddenFields={hiddenFields}
        params={params}
        schema={schema}
        {...this.props}
        classWapperSearchButton={this.props.classWapperSearchButton}
        showSearchButton={showSearchButton}
        searchButtonText={searchButtonText}
        onSubmit={this.handleSubmit}
        validate={validate}
        destroyOnUnmount={destroyOnUnmount}
        onChange={this.handleChange}
        initialValues={initialValues}
        submitLabels={submitLabels}
        isSearch="1"
      />
    );

    const pagination =
      typeof renderPagination === 'function'
        ? renderPagination({
            items,
            total,
            hidePagination,
            onPageChange: this.handlePageChange,
            ...paginationProps,
          })
        : items &&
          (total || this.isGettingTotal(total, items)) &&
          !hidePagination && (
            <Pagination
              total={total || items.length + 1}
              onPageChange={this.handlePageChange}
              disabled={!total}
              showTotalItem={showTotalItem}
              {...paginationProps}
            />
          );

    const neverSubmitted = !hasAnySearchResult && !isFormSubmitting;

    const loading =
      typeof renderLoadingComponent === 'function' ? (
        renderLoadingComponent()
      ) : (
        <Loading circularLoadingIcon />
      );

    return (
      <div className={classWrapper}>
        <div className={classFormFilter} style={style}>
          {searchForm}
        </div>
        <div className={classResultWrapper}>
          {paginationPosition === 'before' && pagination ? (
            <div className={classPaginationWrapper}>{pagination}</div>
          ) : null}
          {neverSubmitted && !alwaysShowResult ? null : (
            <div
              className={`${
                searchFormHasFields ? 'border-top p-t-15 m-t-15' : ''
              }`}
            >
              {isFormSubmitting && loading}
              {showResultsWhenSubmitSucceeded && isFormSubmitting ? null : (
                <Results
                  {...this.props}
                  onSortDataChange={this.handleSortDataChange}
                  ntype={ntype}
                  onSubmit={this.search}
                  noResultTextMultiLine={
                    showSearchButton || (schema && schema.isAdvanceSearch)
                  }
                />
              )}
            </div>
          )}
          {paginationPosition !== 'before' && pagination ? (
            <div className={classPaginationWrapper}>{pagination}</div>
          ) : null}
        </div>
      </div>
    );
  }
}

SearchWrapper.propTypes = {
  alternativeApi: PropTypes.string,
  autoSearchWhenStart: PropTypes.bool,
  autoSearchWhenValuesChange: PropTypes.bool,
  destroyOnUnmount: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  formValues: PropTypes.shape(),
  formid: PropTypes.string.isRequired,
  fromParamsToSortData: PropTypes.func,
  fromSortDataToParams: PropTypes.func,
  hasAnySearchResult: PropTypes.bool,
  hiddenFields: PropTypes.shape(),
  hidePagination: PropTypes.bool,
  initialValues: PropTypes.shape(),
  isFormSubmitting: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  noResultText: PropTypes.string,
  objects: PropTypes.shape(),
  onSubmit: PropTypes.shape(),
  paginationProps: PropTypes.shape(),
  prepareDataBeforeSearch: PropTypes.func,
  renderLoadingComponent: PropTypes.func,
  renderNoResultComponent: PropTypes.func,
  renderResultsComponent: PropTypes.func.isRequired,
  resultId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  searchButtonText: PropTypes.string,
  searchResultKey: PropTypes.string,
  searchValues: PropTypes.shape(),
  showQueryField: PropTypes.bool,
  showResult: PropTypes.bool,
  showSearchButton: PropTypes.bool,
  showResultsWhenSubmitSucceeded: PropTypes.bool,
  total: PropTypes.number.isRequired,
  validate: PropTypes.func,
  fieldsToPersistValueLocalstorage: PropTypes.arrayOf(PropTypes.any),
  paginationPosition: PropTypes.string, // could be either 'before' or 'after'. If 'before', it will be displayed before the Results table
};

SearchWrapper.defaultProps = {
  alternativeApi: null,
  // autoSearchWhenStart: true,
  autoSearchWhenValuesChange: false,
  children: null,
  destroyOnUnmount: false,
  formValues: null,
  hasAnySearchResult: false,
  hiddenFields: {},
  hidePagination: false,
  initialValues: null,
  isFormSubmitting: false,
  noResultText: '',
  objects: {},
  onSubmit: null,
  paginationProps: {},
  prepareDataBeforeSearch: null,
  renderLoadingComponent: null,
  renderNoResultComponent: null,
  resultId: '',
  searchButtonText: '',
  searchResultKey: '',
  searchValues: null,
  showQueryField: false,
  showResult: false,
  showResultsWhenSubmitSucceeded: false,
  statuses: {},
  validate: null,
  fieldsToPersistValueLocalstorage: ['include_sub_organizations'],
  fromSortDataToParams: (sortData) => {
    const sortKey = sortData && Object.keys(sortData)[0];
    const sortValue = sortKey && sortData[sortKey];
    return {
      order_by: sortKey,
      order_value: sortValue,
    };
  },
  fromParamsToSortData: (params) => {
    const sortKey = params && params.order_by;
    const sortValue = params && params.order_value;
    return {
      [sortKey]: sortValue,
    };
  },
  enableDebounceTime: true,
};

const searchResultKeySelector = (state, props) =>
  props.searchResultKey || props.formid;

/**
 *
 * @param formValues
 * @param defaultValues ** Những giá trị mặc định luôn được gửi đi kể cả không được đĩnh nghĩa trường đó **
 * @returns {*} Những giá trị đươc gửi đi. Giá trị của form được ghi đè lên giá trị mặc định.
 */
const getValuesToSubmit = (formValues, defaultValues) => {
  if (!formValues || !defaultValues) {
    return formValues || defaultValues || {};
  }

  const values = { ...formValues };
  Object.keys(defaultValues).forEach((key) => {
    if (Array.isArray(formValues[key]) && !formValues[key].length) {
      values[key] = defaultValues[key];
    } else if (
      typeof formValues[key] === 'undefined' ||
      formValues[key] === null
    ) {
      values[key] = defaultValues[key];
    } else if (
      typeof formValues[key] === 'object' &&
      !Array.isArray(formValues[key]) &&
      typeof defaultValues[key] === 'object' &&
      !Array.isArray(defaultValues[key])
    ) {
      values[key] = getValuesToSubmit(formValues[key], defaultValues[key]);
    }
  });

  return values;
};

const getValues = (state, props = {}) => {
  const { formid } = props;
  return getFormValues(formid)(state);
};

const mapStateToProps = createSelector(
  getValues,
  (state, props) => lodashGet(state, 'domainInfo.conf.auto_search_when_start'),
  (state, props) => isSubmitting(props.formid)(state),
  (state, { formid }) =>
    lodashGet(state, `form.${formid}.redux_form_dispatch_submit`),
  searchResultKeySelector,
  (state, props) =>
    searchResultsSelector(state)(searchResultKeySelector(state, props)),
  (state, props) =>
    searchValuesSelector(state)(searchResultKeySelector(state, props)),
  (state, props) =>
    searchResultObjectsSelector(state)(searchResultKeySelector(state, props)),
  (state, props) =>
    searchResultIdSelector(state)(searchResultKeySelector(state, props)),
  (state, props) =>
    searchResultTotalSelector(state)(searchResultKeySelector(state, props)),
  (state, props) =>
    searchResultShowTotalItemSelector(state)(
      searchResultKeySelector(state, props),
    ),
  (state, props) =>
    hasAnySearchResultSelector(state)(searchResultKeySelector(state, props)),
  (state, props) => props.hiddenFields,
  (state, props) => props.defaultValues,
  (state, props) =>
    resultFromStoreSelector(state)(searchResultKeySelector(state, props)),
  (
    formValues,
    autoSearchWhenStartConfig,
    isFormSubmitting,
    reduxFormDispatchSubmit,
    localSearchResultKey,
    items,
    searchValues,
    objects,
    resultId,
    total,
    showTotalItem,
    hasAnySearchResult,
    hiddenFields,
    defaultValues,
    fullSearchResult,
  ) => {
    let valuesToSubmit = getValuesToSubmit(formValues, defaultValues);
    valuesToSubmit = { ...valuesToSubmit, ...(hiddenFields || {}) };

    return {
      hasAnySearchResult,
      autoSearchWhenStartConfig,
      formValues,
      isFormSubmitting,
      items,
      localSearchResultKey,
      objects,
      searchValues,
      resultId,
      total,
      showTotalItem,
      valuesToSubmit,
      fullSearchResult,
      reduxFormDispatchSubmit,
    };
  },
);

export default connect(mapStateToProps)(withFeatureFlags()(SearchWrapper));
